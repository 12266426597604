<template>

    <div class="component-container query-container" :style="cssVars" >

      <!-- <p class="warning">Please be advised that our weekly updates will be temporarily suspended until January 8th due to our annual collection update. Retroactive updates encompassing the past three weeks may be available after January 8th.</p> -->

        <table>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>See documents</th>
            </tr>

            <tr :class="lineColor(index)" v-for="(file, index) in files" :key="'file_'+index">
              <td>{{ file.from }}</td>
              <td>{{ file.to }}</td>
              <td>
                <button @click="showDocuments(file.from, file.to)" class="button-table">
                  <img src="../assets/images/icon_search.png"  class="mini"/>
              </button>
              </td>
            
            </tr>

        </table>

    </div>

</template>


<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios';

    export default {
      name: 'QueryListBox',
      components: {
      },
      data() {
        return {
          files: [],
          in_progress: false
        };
      },
      computed: {
        ...mapState(['user_query', 'urls', 'theme_color']),
        cssVars () {
            return{
                '--color': this.theme_color.main,
            }
        },
      },
      methods:{
        ...mapActions(['updateQueryDateMin', 'updateQueryDateMax']),
        loadLinks(){
          this.in_progress = true
          var list = this.urls.celltriage+ "results/" + this.user_query.view + "/ids.txt"
          //var documents = []
          axios
            .get(list)
            .then(response => {
                this.error = null
                  var weeks = response.data.split("\n")
                  for (var i=0; i<weeks.length; i++){
                    if (weeks[i] != ""){
                      var name_info = weeks[i].split("_")
                      this.files.push({"from": name_info[0], "to": name_info[1]})
                    }
                  }
                  this.in_progress = false              
              })
              .catch(error => {
                alert(error)
                this.in_progress = false  
              })            
        },
        lineColor(index){
            if(index % 2 == 0){
                return "even"
            }
            else {
                return "odd"
            }
        },
        showDocuments(min, max){
          this.$matomo?.trackEvent("Celltriage documents", min+"-"+max);
          this.updateQueryDateMin(min)
          this.updateQueryDateMax(max)
          this.$emit('showDocuments')
        }
      },
      mounted () {
        this.loadLinks()
      }
    }

</script>


<style scoped lang="scss">

th{

    height: 30px;
    text-align: center;
    padding: 0 10px;
    margin: auto;
}

.mini {
        width: 15px;
    }

    .button-table {
      display: inline-block;
      border-radius:5px;
      background-color: #AAA;
      border: none;
      color: #FFFFFF;
      text-align: center;
      padding: 5px 5px;
      cursor: pointer;
    }

    .button-table:hover {
      background-color: var(--color);
    }
    td {
      border-top: 1px solid #ddd;
      padding: 6px;
      text-align: center;
    }


      table{
        margin: auto;
        font-size: 1.2em
      }

.warning {
  background-color: #ffcc00;
  padding: 20px;
}

</style>
