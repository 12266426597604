<template>

    <div class="component-container">

    <div v-show="user_query.pmcviewer == null">

      <h1 id="results-section">Results for {{ user_query.date_min }} to {{ user_query.date_max }} </h1>

        <b-tabs content-class="mt-3" justified pills>
            <ResultsSearchCollection ref="resultsSearchMedline" :collection_title="collections['medline']" collection_name="medline" :tab="1"/>
        </b-tabs>

        <div class="right-container">
          <ExecuteButton button_text="Back" @click="back()"/>
        </div>
    
    </div>

    <div v-show="user_query.pmcviewer != null">
        <button class="link-button" @click="hideViewerFullPage">Back to results</button>
        <FulltextViewer v-if="user_query.pmcviewer != null" :id="user_query.pmcviewer.id" :collection="user_query.pmcviewer.collection" :terms_to_highlight="user_query.pmcviewer.highlighted_terms"/>
        <br/><button class="link-button" @click="hideViewerFullPage">Back to results</button>

    </div>


    </div>

</template>


<script>
import ResultsSearchCollection from '@/components/ResultsSearchCollection.vue'
import FulltextViewer from '@/components/FulltextViewer.vue'
import ExecuteButton from '@/components/ExecuteButton.vue'
import { mapState, mapActions } from 'vuex'

    export default {
      name: 'ResultsBox',
      components: {
        ResultsSearchCollection,
        FulltextViewer,
        ExecuteButton
      },
      data() {
        return {
        };
      },
      computed: {
        ...mapState(['user_query', 'collections']),
    },
      methods:{
        ...mapActions(['updateTab', 'updateQueryDateMin', 'updateQueryPmcviewer']),
       search(){
            this.updateTab(0)
            this.$refs.resultsSearchMedline.celltriage()
            window.document.title = this.user_query.date_min + " " + this.user_query.date_max
            this.$router.push({ name: 'CellTriagePage', hash: '#results-section', query: this.$route.query}).catch(()=>{});
       },
       back(){
         this.updateQueryDateMin(null)
         this.$refs.resultsSearchMedline.cancel()
         this.$router.push({ name: 'CellTriagePage', query: {}}).catch(()=>{});
       },
       hideViewerFullPage(){
            this.updateQueryPmcviewer(null)
        }
      },
    }

</script>


<style scoped lang="scss">

  .export-container {
    margin: 40px 0px;
  }

  .right-container {
    text-align: right
  }

  .link-button {
    background: none;
    border: none;
    color: red;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font-size: 1.2em;
  }

  .link-button:hover {
    text-decoration: none; /* Optionnel : enlève le soulignement au survol */
  }

</style>
